import React from 'react';
import * as Styled from './locationTableStyle';
import { useTranslation } from 'react-i18next';
import { addTrailingSlash } from '../../utils';

const LocationTable = ({ Object }) => {
  const [translation] = useTranslation();
  let phones;
  const typeObject = {
    Branch: translation('LocalBranch'),
    Cardlock: translation('UltramarCardlock'),
    Pipeline: translation('PipelineCardlock'),
  };

  return (
    <Styled.TableContainer>
      <Styled.LocationTable striped borderless>
        <Styled.THead>
          <tr>
            <th style={{ width: '20%', border: '0' }}>Address</th>
            <th style={{ width: '20%', border: '0' }}>Phone</th>
            <th style={{ width: '20%', border: '0' }}>Email</th>
            <th style={{ width: '20%', border: '0' }}>Manager</th>
            <th style={{ width: '20%', border: '0' }}>Hours</th>
          </tr>
        </Styled.THead>
        <Styled.TBody>
          {Object?.sort((a, b) =>
            a.node.location.localeCompare(b.node.location)
          ).map((item, index) => (
            <tr key={index}>
              <Styled.Td>
                <Styled.LinkStyle
                  to={addTrailingSlash(
                    '/locations/details/' + item.node.urlSlug
                  )}
                >
                  {item.node.location}
                </Styled.LinkStyle>
                <br />
                {item.node.address1}
                <br />
                {item.node.city},&nbsp;{item.node.province}&nbsp;
                {item.node.postalCode}
              </Styled.Td>
              <Styled.Td>
                {
                  ((phones = item.node.phone.split(' ')),
                  phones.map((phone, index) => (
                    <Styled.LinkStyle2
                      key={index}
                      href={`tel:${phone}`}
                      tabindex="0"
                    >
                      {phone}
                    </Styled.LinkStyle2>
                  )))
                }
              </Styled.Td>
              <Styled.Td>
                <Styled.LinkStyle2
                  href={`mailto:${item?.node?.email}`}
                  tabindex="0"
                >
                  {item?.node?.email}
                </Styled.LinkStyle2>
              </Styled.Td>
              <Styled.Td>{item?.node?.branchManager}</Styled.Td>
              <Styled.Td>{item?.node?.branchHours}</Styled.Td>
            </tr>
          ))}
        </Styled.TBody>
      </Styled.LocationTable>
    </Styled.TableContainer>
  );
};
export default LocationTable;
